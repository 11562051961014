


















import { Component, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import { DASHBOARD, SETTING_KEY } from '@/constants'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    SidebarSwitcher,
  },
})
export default class StudentHeader extends Vue {
  private pickSetting = this.getSelectSetting()
  private CALENDAR_SERVICE_ID = 4
  private showCalendarAndGradeBook = false
  private settingData = [
    {
      id: 1,
      value: DASHBOARD.default,
      name: '初期設定',
    },
    {
      id: 2,
      value: DASHBOARD.review,
      name: '学習のふり返り',
    },
    {
      id: 3,
      value: DASHBOARD.goal,
      name: '目標',
    },
    {
      id: 4,
      value: DASHBOARD.schedule,
      name: '予定表',
    },
  ]

  private getSelectSetting() {
    let select: string = DASHBOARD.default + ''
    if (localStorage.getItem(SETTING_KEY)) {
      select = localStorage.getItem(SETTING_KEY) || select
    }
    return select
  }

  private submit() {
    localStorage.setItem(SETTING_KEY, this.pickSetting)
    this.$router.push('/student/v3/dashboard')
  }
  private async loginUser() {
    await Vue.prototype.$http.httpWithToken.get('/v3/users/loginUser').then((res: any) => {
      this.showCalendarAndGradeBook = res.data.showCalendarAndGradeBook
    })
  }
  private async mounted() {
    await this.loginUser()
  }
  private created() {
    const isNotV3 = !Vue.prototype.$gdlsCookiesV3.isV3()
    isNotV3 && this.$router.push('/')
  }
}
